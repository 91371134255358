var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DataTable',{staticClass:"cart-items-table mb-5",attrs:{"items":_vm.items,"headers":_vm.headers,"template":_vm.template,"title":"Produkty","no-pagination":"","no-sort":""},scopedSlots:_vm._u([{key:"item.product",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cart-items-table__item-name"},[_c('ImgViewer',{attrs:{"src":_vm.thumbPath(item.thumbPath),"width":_vm.thumbSize,"height":_vm.thumbSize,"small-loader":"","contain":""}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.name))])],1)]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantity)+" ")]}},{key:"item.priceNet",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.totalsWithCurrency.net))]),_c('small',{staticClass:"d-block"},[_vm._v(" "+_vm._s(item.pricesWithCurrency.net)+"/"+_vm._s(item.unit)+" ")])]}},{key:"item.priceGross",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.totalsWithCurrency.gross))]),_c('small',{staticClass:"d-block"},[_vm._v(" "+_vm._s(item.pricesWithCurrency.gross)+"/"+_vm._s(item.unit)+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }